@import '../../../styles/customMediaQueries.css';

.body {
  width: 100%;
}

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}

.recaptchaStyle {
  display: flex;
  /* justify-content: center; */
  margin-top: 30px;
}

.error {
  color: red;
  margin-bottom: 4px;
  text-align: left;
}

.success {
  margin-bottom: 4px;
  text-align: left;
}

.description{
  margin-top: 14px;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Gilroy-Regular';
}

.submitButton{
  width: 200px;
  background-color: #3BAA7F;
  border-radius: 32px;
}