@import '../../styles/customMediaQueries.css';

.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  background-color: white;
  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordionLayout {
  margin: 10vh 10vw;

  @media (max-width: 768px) {
    margin: 50px 20px;
  }
}

.contactUsHelp {
  background-color: #3baa7f;
  border-radius: 24px;
  color: white;
  padding: 40px;
  margin-right: 0px;
  margin-bottom: 40px;

  @media (min-width: 769px) {
    width: 42vw;
    margin-right: 40px;
    margin-bottom: 0px;
  }
}

.contactEmailAndTel {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'Gilroy-Regular';
}

.contactMargin {
  margin-right: 16px;
}

.contactUsTitleBottom {
  margin-bottom: 20px;
  font-family: 'Gilroy-Bold';
  font-size: 20px;

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
  }
}

.helpIconStyle {
  padding: 10vh 0px 10vh 150px;
  width: 35vw;

  @media (min-width: 320px) and (max-width: 768px) {
    width: '-webkit-fill-available';
    padding: 6vh 0px;
  }
}

.contactUsTitle {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 50px;
  line-height: normal;

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 40px;
  }
}

.contactUsDescription {
  font-family: 'Gilroy-Bold';
  margin-bottom: 5vh;
  font-size: 20px;
  padding-right: 80px;
  line-height: normal;

  @media (min-width: 320px) and (max-width: 768px) {
    padding-right: 0px;
    font-size: 20px;
  }
}

.contact {
  width: 100vw;
  margin: 0 auto;
  display: flex;
  padding: 8vh 8vw;

  @media (min-width: 320px) and (max-width: 768px) {
    display: block;
  }
}

.contactUsForm {
  padding: 40px;
  background-color: whitesmoke;
  border-radius: 24px;
}

.section {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 100px 24px;

  .contentWrapper {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    h2 {
      font-size: 40px;
      line-height: 48px;
      margin-top: 0;
      font-family: 'Gilroy-Bold';
      font-weight: normal;
    }

    p {
      color: #6b6b6b;
      font-size: 18px;
      margin-bottom: 4rem;
    }
  }

  .contentWrapperLeft {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 100%;
  }

  text-align: center;

  @media (--viewportMedium) {
    padding: 100px 10vw;
  }
}

.aboutUsFlex {
  display: flex;
  margin-top: 40px;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 768px) {
    display: block;
  }
}

.aboutUsTitle {
  font-family: 'Gilroy-Bold';
  font-size: xx-large;
  line-height: normal;
  color: #54c499;
  text-align: start;

  @media (max-width: 768px) {
    font-size: 48px;
    text-align: center;
    line-height: 56px;
  }
}

.aboutUsDescription {
  font-family: 'Gilroy-Regular';
  font-size: large;
  line-height: 32px;
  text-align: start;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
    line-height: 26px;
  }
}

.aboutUsDescriptionII {
  font-family: 'Gilroy-Medium';
  font-size: medium;
  line-height: inherit;
  text-align: start;
  margin-top: 10px;
  line-height: 28px;

  @media (max-width: 768px) {
    font-size: 20px;
    text-align: center;
  }
}

.aboutUsDescriptionIII {
  font-family: 'Gilroy-Bold';
  color: #54c499;
  font-size: large;
  line-height: inherit;
  text-align: start;
  margin-top: 10px;
  line-height: 32px;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;

    margin-top: 20px;
  }
}

.aboutUsContent {
  width: 34vw;

  @media (max-width: 768px) {
    margin: 34px 0px;
    width: 100%;
  }

  @media (min-width: 1800px) {
    width: 25vw;
  }
}

.aboutUsImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.imageContent {
  width: 38vw;
  max-height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    max-height: 100%;
  }

  @media (min-width: 1800px) {
    width: 25vw;
  }
}

.aboutUsButton {
  text-transform: uppercase;
  width: 200px;
  border-radius: 32px;
  margin-top: 30px;

  @media (max-width: 768px) {
    width: 320px;
    font-size: 20px;
    margin: 40px auto;
  }
}

.socialImpactTitle {
  font-family: 'Gilroy-Bold';
  font-size: 60px;
  line-height: 68px;
  margin-top: 36px;
}

.socialImpactDescription {
  font-family: 'Gilroy-Regular';
  line-height: 26px;
  margin: 10px 0px;
  font-size: 18px;
  text-align: left;
}

.socialImpactDescriptionII {
  font-family: 'Gilroy-Bold';
  line-height: 30px;
  margin: 10px 0px;
  font-size: 24px;
  color: #00c986;
}

.labaykFlex {
  display: flex;
  margin: 60px 0px;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 20px 0px;
    justify-content: normal;
  }
}

.labaykFlexRevert {
  display: flex;
  margin: 60px 0px;
  width: 100%;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin: 20px 0px;
    justify-content: normal;
  }
}

.imageContentLimitHeight {
  width: 38vw;
  max-height: 41vh;

  @media (max-width: 1100px) {
    max-height: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-height: 50vh;
  }

  @media (min-width: 1800px) {
    width: 25vw;
    max-height: 21vh;
  }
}

.labaykDescriptionII {
  font-family: 'Gilroy-Bold';
  color: #54c499;
  font-size: large;
  line-height: inherit;
  text-align: start;
  margin-top: 20px;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;

    margin-top: 20px;
  }
}

.labaykDescription {
  font-family: 'Gilroy-Regular';
  font-size: large;
  line-height: 26px;
  text-align: start;
  margin-top: 14px;

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }
}

.labaykDescription {
  font-family: 'Gilroy-Regular';
  font-size: large;
  line-height: 26px;
  text-align: start;
  margin-top: 14px;

  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }
}

.purchaseDescription {
  font-family: 'Gilroy-Regular';
  font-size: 18px;
  line-height: normal;
  text-align: left;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.purchaseProgramTitle {
  font-family: 'Gilroy-Bold';
  font-size: 32px;
  line-height: normal;
  color: #54c499;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 48px;
    text-align: center;
    line-height: 56px;
  }
}

.disclaimerStatusContent {
  width: 100%;
  font-family: 'Gilroy-Bold';
  font-size: x-large;
  line-height: 30px;
  color: #54c499;
  text-align: start;

  @media (max-width: 520px) {
    text-align: center;
  }
}

.disclaimerTitle {
  width: 100%;
  text-align: start;
  font-family: 'Gilroy-Bold';
  font-size: xx-large;
  line-height: 56px;
  margin: 20px 0px 0px 0px;

  @media (max-width: 520px) {
    text-align: center;
    line-height: 40px;
  }
}

.disclaimerTopic {
  width: 100%;
  text-align: start;
  font-family: 'Gilroy-SemiBold';
  font-size: x-large;
  font-weight: bold;
  margin: 10px 0px;

  @media (max-width: 520px) {
    text-align: center;
  }
}

.disclaimerContent {
  width: 100%;
  text-align: start;
  font-family: 'Gilroy-Regular';
  line-height: normal;
  margin: 10px 0px;

  @media (max-width: 520px) {
    text-align: center;
  }
}

.disclaimerContentTopic {
  width: 100%;
  text-align: start;
  font-family: 'Gilroy-Regular';
  line-height: normal;
  font-size: 18px;
  margin: 10px 0px;

  @media (max-width: 520px) {
    text-align: center;
  }
}

.disclaimerContentDot {
  width: 100%;
  text-align: start;
  font-family: 'Gilroy-Regular';
  line-height: normal;
  margin: 10px 0px;
  align-items: baseline;
  display: flex;
  font-size: 15px;
}

.disclaimerContentDot::before {
  content: '•';
  font-size: 15px; /* Adjust the size of the dot */
  color: black; /* Adjust the color of the dot */
  margin-right: 10px; /* Adjust the spacing between the dot and the content */
}

.TNCContentDot {
  width: 100%;
  text-align: start;
  font-family: 'Gilroy-Regular';
  line-height: normal;
  margin: 10px 0px;
  font-size: 15px;
}

.TNCContentDot::before {
  content: '•';
  font-size: 15px; /* Adjust the size of the dot */
  color: black; /* Adjust the color of the dot */
  margin-right: 6px; /* Adjust the spacing between the dot and the content */
}

.dotTitle {
  font-family: 'Gilroy-Bold';
}

.readTNC {
  width: 100%;
  text-align: start;
  font-family: 'Gilroy-Regular';
  margin: 10px 0px 0px 0px;

  @media (max-width: 520px) {
    text-align: center;
  }
}
