@import '../../../../styles/customMediaQueries.css';

.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}

.flexAlignment {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.mainImageBlockContainer {
  padding: 64px 32px 0 32px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  &:first-child {
    padding: 32px;
  }
}

.mainImage {
  width: 100vw;

  @media (--viewportMedium) {
    width: 40%;
    max-width: 600px;
  }
}