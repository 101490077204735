.accordion {
  /* border: 1px solid #ccc; */
}

.accordionItem {
  /* border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 2vh; */
}
.accordionItemActive {
  border: 1px solid #ccc;
  padding: 20px;
  cursor: pointer;
  border-radius: 24px;
  margin-bottom: 2vh;
  background-color: white;

  @media (min-width: 200px) and (max-width: 768px) {
    padding: 15px;
  }
}

.accordionTitle {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 2vh;
  border-radius: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-size: 20px;

  @media (min-width: 200px) and (max-width: 768px) {
    padding: 15px;
    font-size: 16px;
  }
}

.accordionContent {
  display: none;
  padding: 10px;
}

.accordionActive {
  display: block;
  font-weight: bold;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-size: 20px;

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 16px;
  }
}

.accordionActiveContent {
  display: block;
  padding: 20px 0px;
}

.defaultAccordion {
  padding: 10px;
  background-color: white;
  text-align: center;
  border: 1px solid #3baa7f;
  border-radius: 24px;
  margin-right: 10px;
  width: 40px;

  @media (min-width: 200px) and (max-width: 768px) {
    margin-right: 0px;
    width: 34px;
  }
}

.closeAccordion {
  padding: 10px;
  background-color: #3baa7f;
  text-align: center;
  border: 1px solid #3baa7f;
  border-radius: 24px;
  margin-right: 10px;
  width: 40px;

  @media (min-width: 200px) and (max-width: 768px) {
    margin-right: 0px;
    width: 34px;
  }
}
